
import { take, map, first } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { SensorSchema } from '../helpers/sensor-schema';

@Injectable()
export class HttpService {
   returnValue: any; // for only unit test
   private sensorSchemaTemplate: SensorSchema;
   constructor(private db: AngularFireDatabase) {
      this.sensorSchemaTemplate = {
         address: '',
         alerts: {
            Calibrations: {
               CycleMultipleError: {
                  Action: 'notify',
                  AdminChangeable: true,
                  AdminVisible: true,
                  BodyEmailTemplate: '<div>{{Name}} is {{Event}}</div>',
                  Comments: 'A Cycle are not multiple of 3',
                  CurrentReading: 0,
                  Enabled: false,
                  ErrorCode: 9999997,
                  High: 0,
                  highEnabled: false,
                  Label: 'Cycle multiple erroor',
                  Low: 0,
                  lowEnabled: false,
                  key: '',
                  NotifyList: [],
                  Order: 1,
                  SubjectEmailTemplate: 'Calibration: {{Name}}',
                  UserChangeable: false,
                  UserVisible: false,
               },
               NumberOfStepsError: {
                  Action: 'notify',
                  AdminChangeable: true,
                  AdminVisible: true,
                  BodyEmailTemplate: '<div>{{Name}} is {{Event}}</div>',
                  Comments: 'Number of steps are not 42',
                  CurrentReading: 0,
                  Enabled: false,
                  ErrorCode: 9999998,
                  High: 0,
                  highEnabled: false,
                  Label: 'Number of stepss',
                  Low: 0,
                  lowEnabled: false,
                  key: '',
                  NotifyList: [],
                  Order: 2,
                  SubjectEmailTemplate: 'Calibration: {{Name}}',
                  UserChangeable: false,
                  UserVisible: false,
               },
               ProcessingFailedError: {
                  Action: 'notify',
                  AdminChangeable: true,
                  AdminVisible: true,
                  BodyEmailTemplate: '<div>{{Name}} is {{Event}}</div>',
                  Comments: 'Calibration processing failed due to any reason',
                  CurrentReading: 0,
                  Enabled: false,
                  ErrorCode: 9999999,
                  High: 0,
                  highEnabled: false,
                  Label: 'Calibration processing',
                  Low: 0,
                  lowEnabled: false,
                  key: '',
                  NotifyList: [],
                  Order: 3,
                  SubjectEmailTemplate: 'Calibration: {{Name}}',
                  UserChangeable: false,
                  UserVisible: false,
               },
               gasEmailList: { Enabled: false },
            },
            Errors: {
               ConfigurationAssigned: {
                  Action: 'notify',
                  AdminChangeable: false,
                  AdminVisible: false,
                  BodyEmailTemplate: '{{Name}} error: {{message_text}}',
                  Comments: 'A Configuration Assigned',
                  CurrentReading: 0,
                  Enabled: false,
                  ErrorCode: 154,
                  High: '0',
                  highEnabled: false,
                  Label: 'A Configuration Assigned',
                  Low: '0',
                  key: '',
                  lowEnabled: false,
                  NotifyList: [],
                  Order: 15,
                  SubjectEmailTemplate: 'Alert: {{Name}}',
                  UserChangeable: false,
                  UserVisible: false,
               },
               DetectorCapacitance: {
                  Action: 'stopCycle',
                  AdminChangeable: true,
                  AdminVisible: true,
                  BodyEmailTemplate:
                     '<div>Sensor {{Sensor}} reported an [{{Name}}] error during regular run cycle.</div><div>Please log in to the portal and check if this error need to be cleared. If this is happening repeatedly, please contact support@omniscent.com</div><div>Additional info: <br/>Error: {{Name}}<br/>Message: {{message_text}}<br/>Sub-module:{{sub_module}}</div>',
                  Comments: 'Detector Capacitance',
                  CurrentReading: 0,
                  Enabled: false,
                  ErrorCode: 108,
                  High: 0,
                  highEnabled: true,
                  Label: 'Detector Capacitance',
                  Low: 0,
                  key: '',
                  lowEnabled: true,
                  NotifyList: [],
                  Order: 9,
                  SubjectEmailTemplate: 'Omniscent Alert [{{Name}}] from {{Sensor}}',
                  UserChangeable: false,
                  UserVisible: false,
               },
               EnclTemp: {
                  Action: 'stopCycle',
                  AdminChangeable: true,
                  AdminVisible: true,
                  BodyEmailTemplate:
                     '<div>Sensor {{Sensor}} reported an [{{Name}}] error during regular run cycle.</div><div>Please log in to the portal and check if this error need to be cleared. If this is happening repeatedly, please contact support@omniscent.com</div><div>Additional info: <br/>Error: {{Name}}<br/>Message: {{message_text}}<br/>Sub-module:{{sub_module}}</div>',
                  Comments: 'From sensor on the board',
                  CurrentReading: 0,
                  Enabled: false,
                  ErrorCode: 104,
                  High: 0,
                  highEnabled: true,
                  Label: 'Encl Temp Sensor',
                  Low: 0,
                  key: '',
                  lowEnabled: true,
                  NotifyList: [],
                  Order: 5,
                  SubjectEmailTemplate: 'Omniscent Alert [{{Name}}] from {{Sensor}}',
                  UserChangeable: false,
                  UserVisible: false,
               },
               SupplyVoltage: {
                  Action: 'stopCycle',
                  AdminChangeable: true,
                  AdminVisible: true,
                  BodyEmailTemplate:
                     '<div>Sensor {{Sensor}} reported an [{{Name}}] error during regular run cycle.</div><div>Please log in to the portal and check if this error need to be cleared. If this is happening repeatedly, please contact support@omniscent.com</div><div>Additional info: <br/>Error: {{Name}}<br/>Message: {{message_text}}<br/>Sub-module:{{sub_module}}</div>',
                  Comments: 'From sensor on the board',
                  CurrentReading: 0,
                  Enabled: false,
                  ErrorCode: 157,
                  High: 14,
                  highEnabled: true,
                  Label: 'Supply Voltage',
                  Low: 9,
                  key: '',
                  lowEnabled: true,
                  NotifyList: [],
                  Order: 5,
                  SubjectEmailTemplate: 'Omniscent Alert [{{Name}}] from {{Sensor}}',
                  UserChangeable: false,
                  UserVisible: false,
               },
               FlowRateSensor1: {
                  Action: 'stopCycle',
                  AdminChangeable: true,
                  AdminVisible: true,
                  BodyEmailTemplate:
                     '<div>Sensor {{Sensor}} reported an [{{Name}}] error during regular run cycle.</div><div>Please log in to the portal and check if this error need to be cleared. If this is happening repeatedly, please contact support@omniscent.com</div><div>Additional info: <br/>Error: {{Name}}<br/>Message: {{message_text}}<br/>Sub-module:{{sub_module}}</div>',
                  Comments: 'Flow Rate Sensor #1',
                  CurrentReading: 0,
                  Enabled: false,
                  ErrorCode: 102,
                  High: 0,
                  highEnabled: true,
                  Label: 'Flow Rate Sensor #1',
                  Low: 0,
                  lowEnabled: true,
                  key: '',
                  NotifyList: [],
                  Order: 3,
                  SubjectEmailTemplate: 'Omniscent Alert [{{Name}}] from {{Sensor}}',
                  UserChangeable: false,
                  UserVisible: false,
               },
               FlowRateSensor2: {
                  Action: 'stopCycle',
                  AdminChangeable: true,
                  AdminVisible: true,
                  BodyEmailTemplate:
                     '<div>Sensor {{Sensor}} reported an [{{Name}}] error during regular run cycle.</div><div>Please log in to the portal and check if this error need to be cleared. If this is happening repeatedly, please contact support@omniscent.com</div><div>Additional info: <br/>Error: {{Name}}<br/>Message: {{message_text}}<br/>Sub-module:{{sub_module}}</div>',
                  Comments: 'Measure during Sepration',
                  CurrentReading: 0,
                  Enabled: false,
                  ErrorCode: 100,
                  highEnabled: true,
                  High: 0,
                  Label: 'Flow Rate Sensor #2',
                  Low: 0,
                  lowEnabled: true,
                  key: '',
                  NotifyList: [],
                  Order: 2,
                  SubjectEmailTemplate: 'Omniscent Alert [{{Name}}] from {{Sensor}}',
                  UserChangeable: false,
                  UserVisible: false,
               },
               FlowRateSensor3: {
                  Action: 'stopCycle',
                  AdminChangeable: true,
                  AdminVisible: true,
                  BodyEmailTemplate:
                     '<div>Sensor {{Sensor}} reported an [{{Name}}] error during regular run cycle.</div><div>Please log in to the portal and check if this error need to be cleared. If this is happening repeatedly, please contact support@omniscent.com</div><div>Additional info: <br/>Error: {{Name}}<br/>Message: {{message_text}}<br/>Sub-module:{{sub_module}}</div>',
                  Comments: 'Flow Rate Sensor #3',
                  CurrentReading: 0,
                  Enabled: false,
                  ErrorCode: 103,
                  High: 0,
                  highEnabled: true,
                  Label: 'Flow Rate Sensor #3',
                  Low: 0,
                  key: '',
                  lowEnabled: true,
                  NotifyList: [],
                  Order: 4,
                  SubjectEmailTemplate: 'Omniscent Alert [{{Name}}] from {{Sensor}}',
                  UserChangeable: false,
                  UserVisible: false,
               },
               HumidityLevel: {
                  Action: 'stopCycle',
                  AdminChangeable: true,
                  AdminVisible: true,
                  BodyEmailTemplate:
                     '<div>Sensor {{Sensor}} reported an [{{Name}}] error during regular run cycle.</div><div>Please log in to the portal and check if this error need to be cleared. If this is happening repeatedly, please contact support@omniscent.com</div><div>Additional info: <br/>Error: {{Name}}<br/>Message: {{message_text}}<br/>Sub-module:{{sub_module}}</div>',
                  Comments: 'Measure during Sepration',
                  CurrentReading: 0,
                  Enabled: false,
                  ErrorCode: 101,
                  High: 0,
                  highEnabled: true,
                  Label: 'Humidity Level',
                  Low: 0,
                  lowEnabled: false,
                  key: '',
                  NotifyList: [],
                  Order: 1,
                  SubjectEmailTemplate: 'Omniscent Alert [{{Name}}] from {{Sensor}}',
                  UserChangeable: false,
                  UserVisible: false,
               },
               NewSensorMade: {
                  Action: 'notify',
                  AdminChangeable: false,
                  AdminVisible: false,
                  BodyEmailTemplate: '{{Name}} error: {{message_text}}',
                  Comments: 'New Sensor Made/Deleted',
                  CurrentReading: 0,
                  Enabled: false,
                  ErrorCode: 155,
                  High: 0,
                  highEnabled: false,
                  Label: 'New Sensor Made/Deleted',
                  Low: 0,
                  key: '',
                  lowEnabled: false,
                  NotifyList: [],
                  Order: 16,
                  SubjectEmailTemplate: 'Alert: {{Name}}',
                  UserChangeable: false,
                  UserVisible: false,
               },
               NumberOfRunExceeded: {
                  Action: 'notify',
                  AdminChangeable: false,
                  AdminVisible: false,
                  BodyEmailTemplate: '{{Name}} error: {{message_text}}',
                  Comments: 'Number of run exceeded',
                  CurrentReading: 0,
                  Enabled: false,
                  ErrorCode: 156,
                  Label: 'Number of run exceeded',
                  High: '9999',
                  highEnabled: true,
                  key: '',
                  Low: '0',
                  lowEnabled: false,
                  NotifyList: [],
                  Order: 17,
                  SubjectEmailTemplate: 'Alert: {{Name}}',
                  UserChangeable: false,
                  UserVisible: false,
               },
               PreconcentratorTempDuringSampling: {
                  Action: 'stopCycle',
                  AdminChangeable: false,
                  AdminVisible: false,
                  BodyEmailTemplate: '{{Name}} error: {{message_text}}',
                  Comments: 'Preconcentrator Temp During Sampling',
                  CurrentReading: 0,
                  Enabled: false,
                  highEnabled: true,
                  ErrorCode: 107,
                  High: '0',
                  Label: 'Preconcentrator Temp During Sampling',
                  Low: '0',
                  lowEnabled: true,
                  key: '',
                  NotifyList: [],
                  Order: 8,
                  SubjectEmailTemplate: 'Alert: {{Name}}',
                  UserChangeable: false,
                  UserVisible: false,
               },
               PreconcentratorTempDuringSeparation: {
                  Action: 'stopCycle',
                  AdminChangeable: true,
                  AdminVisible: true,
                  BodyEmailTemplate:
                     '<div>Sensor {{Sensor}} reported an [{{Name}}] error during regular run cycle.</div><div>Please log in to the portal and check if this error need to be cleared. If this is happening repeatedly, please contact support@omniscent.com</div><div>Additional info: <br/>Error: {{Name}}<br/>Message: {{message_text}}<br/>Sub-module:{{sub_module}}</div>',
                  Comments: 'Preconcentrator Temp during separation',
                  CurrentReading: 0,
                  Enabled: false,
                  ErrorCode: 106,
                  High: 0,
                  highEnabled: true,
                  Label: 'Preconcentrator Temp during separation',
                  Low: 0,
                  lowEnabled: true,
                  key: '',
                  NotifyList: [],
                  Order: 7,
                  SubjectEmailTemplate: 'Alert: {{Name}}',
                  UserChangeable: false,
                  UserVisible: false,
               },
               RunStop: {
                  Action: 'notify',
                  AdminChangeable: true,
                  AdminVisible: false,
                  BodyEmailTemplate: '{{Name}} error: {{message_text}}',
                  Comments: 'Run Stop by Usr',
                  CurrentReading: 0,
                  Enabled: false,
                  ErrorCode: 152,
                  Label: 'Run Stop by Usr',
                  High: '0',
                  highEnabled: false,
                  key: '',
                  Low: 0,
                  lowEnabled: false,
                  NotifyList: [],
                  Order: 13,
                  SubjectEmailTemplate: 'Alert: {{Name}}',
                  UserChangeable: false,
                  UserVisible: false,
               },
               SensorOffline: {
                  Action: 'notify',
                  AdminChangeable: false,
                  AdminVisible: false,
                  BodyEmailTemplate: '{{Name}} error: {{message_text}}',
                  Comments: 'Sensor Go Off Line',
                  CurrentReading: 0,
                  Enabled: false,
                  ErrorCode: 151,
                  High: 0,
                  highEnabled: false,
                  Label: 'Sensor Go Off Line',
                  Low: 0,
                  lowEnabled: false,
                  key: '',
                  NotifyList: [],
                  Order: 12,
                  SubjectEmailTemplate: 'Alert: {{Name}}',
                  UserChangeable: false,
                  UserVisible: false,
               },
               SetOfRunsStarted: {
                  Action: 'notify',
                  AdminChangeable: false,
                  AdminVisible: false,
                  BodyEmailTemplate: '{{Name}} error: {{message_text}}',
                  Comments: 'Set of runs Started',
                  CurrentReading: 0,
                  Enabled: false,
                  ErrorCode: 153,
                  Label: 'Set of runs Started',
                  High: '10',
                  highEnabled: false,
                  key: '',
                  Low: '0',
                  lowEnabled: false,
                  NotifyList: [],
                  Order: 14,
                  SubjectEmailTemplate: 'Alert: {{Name}}',
                  UserChangeable: false,
                  UserVisible: false,
               },
               SystemReboot: {
                  Action: 'restartNextCycle',
                  AdminChangeable: true,
                  AdminVisible: true,
                  BodyEmailTemplate:
                     '<div>Sensor {{Sensor}} reported an [{{Name}}] error during regular run cycle.</div><div>Please log in to the portal and check if this error need to be cleared. If this is happening repeatedly, please contact support@omniscent.com</div><div>Additional info: <br/>Error: {{Name}}<br/>Message: {{message_text}}<br/>Sub-module:{{sub_module}}</div>',
                  Comments: 'System Reboot',
                  CurrentReading: 0,
                  Enabled: false,
                  ErrorCode: 150,
                  High: 0,
                  highEnabled: false,
                  Label: 'System Reboot',
                  Low: 0,
                  lowEnabled: false,
                  key: '',
                  NotifyList: [],
                  Order: 11,
                  SubjectEmailTemplate: 'Omniscent Alert [{{Name}}] from {{Sensor}}',
                  UserChangeable: false,
                  UserVisible: false,
               },
               TempBelowDewPoint: {
                  Action: 'notify',
                  AdminChangeable: true,
                  AdminVisible: true,
                  BodyEmailTemplate:
                     '<div>Sensor {{Sensor}} reported an [{{Name}}] error during regular run cycle.</div><div>Please log in to the portal and check if this error need to be cleared. If this is happening repeatedly, please contact support@omniscent.com</div><div>Additional info: <br/>Error: {{Name}}<br/>Message: {{message_text}}<br/>Sub-module:{{sub_module}}</div>',
                  Comments: 'Dew point is calculated in the sensor',
                  CurrentReading: 0,
                  Enabled: false,
                  ErrorCode: 105,
                  Label: 'Temp Below Dew Point',
                  High: 0,
                  highEnabled: true,
                  key: '',
                  Low: 0,
                  lowEnabled: false,
                  NotifyList: [],
                  Order: 6,
                  SubjectEmailTemplate: 'Omniscent Alert [{{Name}}] from {{Sensor}}',
                  UserChangeable: false,
                  UserVisible: false,
               },
               WeatherDataMissing: {
                  Action: 'notify',
                  AdminChangeable: false,
                  AdminVisible: false,
                  BodyEmailTemplate: '{{Name}} error: {{message_text}}',
                  Comments: 'Triggered when wind sensor configured but no wind data recorded during a run',
                  CurrentReading: 0,
                  Enabled: false,
                  ErrorCode: 200,
                  High: 0,
                  highEnabled: false,
                  Label: 'Wind Data Missing',
                  Low: 0,
                  lowEnabled: false,
                  key: '',
                  NotifyList: [],
                  Order: 18,
                  SubjectEmailTemplate: 'Alert: {{Name}}',
                  UserChangeable: false,
                  UserVisible: false,
               },
               i2cBus: {
                  Action: 'stopCycle',
                  AdminChangeable: true,
                  AdminVisible: true,
                  BodyEmailTemplate:
                     '<div>Sensor {{Sensor}} reported an [{{Name}}] error during regular run cycle.</div><div>Please log in to the portal and check if this error need to be cleared. If this is happening repeatedly, please contact support@omniscent.com</div><div>Additional info: <br/>Error: {{Name}}<br/>Message: {{message_text}}<br/>Sub-module:{{sub_module}}</div>',
                  Comments: 'If any sensor on i2c bus not responding',
                  CurrentReading: 0,
                  Enabled: false,
                  ErrorCode: 109,
                  Label: 'If any sensor on i2c bus not responding',
                  High: 0,
                  highEnabled: true,
                  key: '',
                  Low: 0,
                  lowEnabled: false,
                  NotifyList: [],
                  Order: 10,
                  SubjectEmailTemplate: 'Omniscent Alert [{{Name}}] from {{Sensor}}',
                  UserChangeable: false,
                  UserVisible: false,
               },
               dataProcessing: {
                  Action: 'notify',
                  AdminChangeable: true,
                  AdminVisible: true,
                  BodyEmailTemplate:
                     '<div>Sensor {{Sensor}} reported an [{{Name}}] error during regular run cycle.</div><div>Please log in to the portal and check if this error need to be cleared. If this is happening repeatedly, please contact support@omniscent.com</div><div>Additional info: <br/>Error: {{Name}}<br/>Message: {{message_text}}<br/>Sub-module:{{sub_module}}</div>',
                  Comments: 'data processing error',
                  CurrentReading: 0,
                  Enabled: false,
                  ErrorCode: 158,
                  Label: 'Data processing ',
                  High: 0,
                  highEnabled: false,
                  key: '',
                  Low: 0,
                  lowEnabled: false,
                  NotifyList: [],
                  Order: 10,
                  SubjectEmailTemplate: 'Omniscent Alert [{{Name}}] from {{Sensor}}',
                  UserChangeable: false,
                  UserVisible: false,
               },
              
               chemicalRecognition: {
                  Action: 'notify',
                  AdminChangeable: true,
                  AdminVisible: true,
                  BodyEmailTemplate:
                     '<div>Sensor {{Sensor}} reported an [{{Name}}] error during regular run cycle.</div><div>Please log in to the portal and check if this error need to be cleared. If this is happening repeatedly, please contact support@omniscent.com</div><div>Additional info: <br/>Error: {{Name}}<br/>Message: {{message_text}}<br/>Sub-module:{{sub_module}}</div>',
                  Comments: 'Chemical recognition error',
                  CurrentReading: 0,
                  Enabled: false,
                  ErrorCode: 159,
                  Label: 'Chemical recognition',
                  High: 0,
                  highEnabled: false,
                  key: '',
                  Low: 0,
                  lowEnabled: false,
                  NotifyList: [],
                  Order: 10,
                  SubjectEmailTemplate: 'Omniscent Alert [{{Name}}] from {{Sensor}}',
                  UserChangeable: false,
                  UserVisible: false,
               },
               configurationProblem: {
                  Action: "notify",
                  AdminChangeable: true,
                  AdminVisible: true,
                  BodyEmailTemplate: "<div>Sensor {{Sensor}} reported a problem with the configuration. <br /></div><div>Additional info: <br/>Error: {{Name}}<br/>Message: {{message_text}}</div><div>If you believe that this is in error, or this is happening repeatedly, please contact support@omniscent.com</div>",
                  Comments: "If any configuration conflict is detected",
                  CurrentReading: 0,
                  Enabled: true,
                  ErrorCode: 160,
                  High: 0,
                  Label: "Configuration problem",
                  Low: 0,
                  Order: 11,
                  SubjectEmailTemplate: "Omniscent Alert [{{Name}}] from {{Sensor}} - Configuration Issue",
                  UserChangeable: false,
                  UserVisible: false,
                  NotifyList: [],
                  highEnabled: false,
                  lowEnabled: false,
                  key: ''
               },
            },
            Gases: {
               UnknownGas: {
                  Action: 'notify',
                  AdminChangeable: false,
                  AdminVisible: false,
                  BodyEmailTemplate:
                     '<div>Sensor {{Sensor}} detected concentration of {{Name}} outside of the configured range.</div><div>Configured Concentration Range (ppb): {{Low}} - {{High}}</div><div><b>Actual Detected Concentration (ppb): {{ChemConc}}</b></div>',
                  Comments: 'Unknown Gas(es) level (ppb)',
                  CurrentReading: 0,
                  Enabled: false,
                  ErrorCode: 117,
                  High: 0,
                  highEnabled: true,
                  Label: 'Unknown Gas(es) level (ppb)',
                  Low: 0,
                  lowEnabled: false,
                  key: '',
                  NotifyList: [],
                  Order: 6,
                  SubjectEmailTemplate: 'Omniscent [{{Name}}] detection alert from {{Sensor}}',
                  UserChangeable: false,
                  UserVisible: false,
               },
               UnknownGas1: {
                  Action: 'notify',
                  AdminChangeable: false,
                  AdminVisible: false,
                  BodyEmailTemplate:
                     '<div>Sensor {{Sensor}} detected concentration of {{Name}} outside of the configured range.</div><div>Configured Concentration Range (ppb): {{Low}} - {{High}}</div><div><b>Actual Detected Concentration (ppb): {{ChemConc}}</b></div>',
                  Comments: 'Unknown Gas(es) level (ppb)',
                  CurrentReading: 0,
                  Enabled: false,
                  ErrorCode: 117,
                  High: 0,
                  highEnabled: true,
                  Label: 'Unknown Gas(es) level (ppb)',
                  Low: 0,
                  lowEnabled: false,
                  key: '',
                  NotifyList: [],
                  Order: 6,
                  SubjectEmailTemplate: 'Omniscent [{{Name}}] detection alert from {{Sensor}}',
                  UserChangeable: false,
                  UserVisible: false,
               },
               benzene: {
                  Action: 'notify',
                  AdminChangeable: true,
                  AdminVisible: true,
                  BodyEmailTemplate:
                     '<div>Sensor {{Sensor}} detected concentration of {{Name}} outside of the configured range.</div><div>Configured Concentration Range (ppb): {{Low}} - {{High}}</div><div><b>Actual Detected Concentration (ppb): {{ChemConc}}</b></div>',
                  Comments: 'benzene level (ppb)',
                  CurrentReading: 0,
                  Enabled: false,
                  ErrorCode: 111,
                  High: 0,
                  highEnabled: true,
                  Label: 'benzene',
                  Low: 0,
                  lowEnabled: false,
                  key: '',
                  NotifyList: [],
                  Order: 2,
                  SubjectEmailTemplate: 'Omniscent [{{Name}}] detection alert from {{Sensor}}',
                  UserChangeable: false,
                  UserVisible: false,
               },
               ethylbenzene: {
                  Action: 'notify',
                  AdminChangeable: false,
                  AdminVisible: false,
                  BodyEmailTemplate:
                     '<div>Sensor {{Sensor}} detected concentration of {{Name}} outside of the configured range.</div><div>Configured Concentration Range (ppb): {{Low}} - {{High}}</div><div><b>Actual Detected Concentration (ppb): {{ChemConc}}</b></div>',
                  Comments: 'Ethylbenzene level (ppb)',
                  CurrentReading: 0,
                  Enabled: false,
                  highEnabled: true,
                  ErrorCode: 116,
                  High: 0,
                  Label: 'Ethylbenzene level (ppb)',
                  Low: 0,
                  key: '',
                  lowEnabled: false,
                  NotifyList: [],
                  Order: 8,
                  SubjectEmailTemplate: 'Omniscent [{{Name}}] detection alert from {{Sensor}}',
                  UserChangeable: false,
                  UserVisible: false,
               },
               'm-xylene': {
                  Action: 'notify',
                  AdminChangeable: true,
                  AdminVisible: true,
                  BodyEmailTemplate:
                     '<div>Sensor {{Sensor}} detected concentration of {{Name}} outside of the configured range.</div><div>Configured Concentration Range (ppb): {{Low}} - {{High}}</div><div><b>Actual Detected Concentration (ppb): {{ChemConc}}</b></div>',
                  Comments: 'm-xylene level (ppb)',
                  CurrentReading: 0,
                  Enabled: false,
                  ErrorCode: 113,
                  High: 0,
                  highEnabled: true,
                  Label: 'm-xylene',
                  Low: 0,
                  lowEnabled: false,
                  key: '',
                  NotifyList: [],
                  Order: 4,
                  SubjectEmailTemplate: 'Omniscent [{{Name}}] detection alert from {{Sensor}}',
                  UserChangeable: false,
                  UserVisible: false,
               },
               methylal: {
                  Action: 'notify',
                  AdminChangeable: false,
                  AdminVisible: false,
                  BodyEmailTemplate:
                     '<div>Sensor {{Sensor}} detected concentration of {{Name}} outside of the configured range.</div><div>Configured Concentration Range (ppb): {{Low}} - {{High}}</div><div><b>Actual Detected Concentration (ppb): {{ChemConc}}</b></div>',
                  Comments: 'Methylal level (ppb)',
                  CurrentReading: 0,
                  Enabled: false,
                  ErrorCode: 118,
                  High: 0,
                  highEnabled: true,
                  Label: 'Methylal level (ppb)',
                  Low: 0,
                  lowEnabled: false,
                  key: '',
                  NotifyList: [],
                  Order: 9,
                  SubjectEmailTemplate: 'Omniscent [{{Name}}] detection alert from {{Sensor}}',
                  UserChangeable: false,
                  UserVisible: false,
               },
               'o-xylene': {
                  Action: 'notify',
                  AdminChangeable: true,
                  AdminVisible: true,
                  BodyEmailTemplate:
                     '<div>Sensor {{Sensor}} detected concentration of {{Name}} outside of the configured range.</div><div>Configured Concentration Range (ppb): {{Low}} - {{High}}</div><div><b>Actual Detected Concentration (ppb): {{ChemConc}}</b></div>',
                  Comments: 'o-xylene level (ppb)',
                  CurrentReading: 0,
                  Enabled: false,
                  ErrorCode: 114,
                  High: 0,
                  highEnabled: true,
                  Label: 'o-xylene',
                  Low: 0,
                  lowEnabled: false,
                  key: '',
                  NotifyList: [],
                  Order: 5,
                  SubjectEmailTemplate: 'Omniscent [{{Name}}] detection alert from {{Sensor}}',
                  UserChangeable: false,
                  UserVisible: false,
               },
               pidLevel: {
                  Action: 'notify',
                  AdminChangeable: false,
                  AdminVisible: false,
                  BodyEmailTemplate:
                     '<div>Sensor {{Sensor}} detected concentration of {{Name}} outside of the configured range.</div><div>Configured Concentration Range (ppb): {{Low}} - {{High}}</div><div><b>Actual Detected Concentration (ppb): {{ChemConc}}</b></div>',
                  Comments: 'PID level (ppb)',
                  CurrentReading: 0,
                  Enabled: false,
                  ErrorCode: 110,
                  High: 0,
                  highEnabled: true,
                  Label: 'PID level (ppb)',
                  Low: 0,
                  lowEnabled: false,
                  key: '',
                  NotifyList: [],
                  Order: 10,
                  SubjectEmailTemplate: 'Omniscent [{{Name}}] detection alert from {{Sensor}}',
                  UserChangeable: false,
                  UserVisible: false,
               },
               styrene: {
                  Action: 'notify',
                  AdminChangeable: false,
                  AdminVisible: false,
                  BodyEmailTemplate:
                     '<div>Concentration Acceptable Range (ppb): {{Low}} - {{High}}</div><div>Actual Detected Concentration (ppb): {{ChemConc}}</div>',
                  Comments: 'Styrene level (ppb)',
                  CurrentReading: 0,
                  Enabled: false,
                  ErrorCode: 115,
                  High: 0,
                  highEnabled: true,
                  Label: 'Styrene level (ppb)',
                  Low: 0,
                  lowEnabled: false,
                  key: '',
                  NotifyList: [],
                  Order: 7,
                  SubjectEmailTemplate: 'Omniscent [{{Name}}] detection alert from {{Sensor}}',
                  UserChangeable: false,
                  UserVisible: false,
               },
               toluene: {
                  Action: 'notify',
                  AdminChangeable: true,
                  AdminVisible: true,
                  BodyEmailTemplate:
                     '<div>Sensor {{Sensor}} detected concentration of {{Name}} outside of the configured range.</div><div>Configured Concentration Range (ppb): {{Low}} - {{High}}</div><div><b>Actual Detected Concentration (ppb): {{ChemConc}}</b></div>',
                  Comments: 'toluene level (ppb)',
                  CurrentReading: 0,
                  Enabled: false,
                  ErrorCode: 112,
                  High: 0,
                  highEnabled: true,
                  Label: 'toluene',
                  Low: 0,
                  lowEnabled: false,
                  key: '',
                  NotifyList: [],
                  Order: 3,
                  SubjectEmailTemplate: 'Omniscent [{{Name}}] detection alert from {{Sensor}}',
                  UserChangeable: false,
                  UserVisible: false,
               },
               "ethylene dichloride": {
                  Action: 'notify',
                  AdminChangeable: true,
                  AdminVisible: true,
                  BodyEmailTemplate:
                     '<div>Sensor {{Sensor}} detected concentration of {{Name}} outside of the configured range.</div><div>Configured Concentration Range (ppb): {{Low}} - {{High}}</div><div><b>Actual Detected Concentration (ppb): {{ChemConc}}</b></div>',
                  Comments: 'ethylene dichloride level (ppb)',
                  CurrentReading: 0,
                  Enabled: false,
                  ErrorCode: 119,
                  High: 0,
                  highEnabled: true,
                  Label: 'ethylene dichloride',
                  Low: 0,
                  lowEnabled: false,
                  key: '',
                  NotifyList: [],
                  Order: 3,
                  SubjectEmailTemplate: 'Omniscent [{{Name}}] detection alert from {{Sensor}}',
                  UserChangeable: false,
                  UserVisible: false,
               },
               'm-p-xylene': {
                  Action: 'notify',
                  AdminChangeable: true,
                  AdminVisible: true,
                  BodyEmailTemplate:
                     '<div>Sensor {{Sensor}} detected concentration of {{Name}} outside of the configured range.</div><div>Configured Concentration Range (ppb): {{Low}} - {{High}}</div><div><b>Actual Detected Concentration (ppb): {{ChemConc}}</b></div>',
                  Comments: 'm-/p-xylene level (ppb)',
                  CurrentReading: 0,
                  Enabled: false,
                  ErrorCode: 120,
                  High: 0,
                  highEnabled: true,
                  Label: 'm-/p-xylene',
                  Low: 0,
                  lowEnabled: false,
                  key: '',
                  NotifyList: [],
                  Order: 3,
                  SubjectEmailTemplate: 'Omniscent [{{Name}}] detection alert from {{Sensor}}',
                  UserChangeable: false,
                  UserVisible: false,
               },
               heptane: {
                  Action: 'notify',
                  AdminChangeable: true,
                  AdminVisible: true,
                  BodyEmailTemplate:
                     '<div>Sensor {{Sensor}} detected concentration of {{Name}} outside of the configured range.</div><div>Configured Concentration Range (ppb): {{Low}} - {{High}}</div><div><b>Actual Detected Concentration (ppb): {{ChemConc}}</b></div>',
                  Comments: 'heptane level (ppb)',
                  CurrentReading: 0,
                  Enabled: false,
                  ErrorCode: 121,
                  High: 0,
                  highEnabled: true,
                  Label: 'heptane',
                  Low: 0,
                  lowEnabled: false,
                  key: '',
                  NotifyList: [],
                  Order: 3,
                  SubjectEmailTemplate: 'Omniscent [{{Name}}] detection alert from {{Sensor}}',
                  UserChangeable: false,
                  UserVisible: false,
               },
               octane: {
                  Action: 'notify',
                  AdminChangeable: true,
                  AdminVisible: true,
                  BodyEmailTemplate:
                     '<div>Sensor {{Sensor}} detected concentration of {{Name}} outside of the configured range.</div><div>Configured Concentration Range (ppb): {{Low}} - {{High}}</div><div><b>Actual Detected Concentration (ppb): {{ChemConc}}</b></div>',
                  Comments: 'octane level (ppb)',
                  CurrentReading: 0,
                  Enabled: false,
                  ErrorCode: 122,
                  High: 0,
                  highEnabled: true,
                  Label: 'octane',
                  Low: 0,
                  lowEnabled: false,
                  key: '',
                  NotifyList: [],
                  Order: 3,
                  SubjectEmailTemplate: 'Omniscent [{{Name}}] detection alert from {{Sensor}}',
                  UserChangeable: false,
                  UserVisible: false,
               },
            },
         },
         sensorModelGasSelection: [],
         availability: 'on',
         cleaningCycle: '0',
         customerId: '',
         customerName: '',
         description: '',
         fastMode: false,
         fastModeConfigName1: 'Fast Mode Config 1',
         fastModeConfigName2: 'Fast Mode Config 2',
         normalModeConfigName: 'Normal Mode Config',
         fastModeFile: null,
         fastModeFile2: null,
         currentSamplingFile: '',
         currentSamplingFileForFastMode: '',
         normalModeConfigFile: null,
         fastModeSamplingTime: 35,
         normalModeSamplingTime: 60,
         actionStatus: -1,
         key: '',
         lat: '',
         lng: '',
         weather: {
            lat: '',
            lng: '',
         },
         modem: {
            sensorId: '',
            lat: '',
            lng: '',
         },
         chosenGeoLocationOption: '',
         maxRuns: 9999,
         name: 'Name not specified',
         newIgcCalResult: null,
         newIgcCalResultForFast: null,
         oldIgcCalResult: null,
         oldIgcCalResultForFast: null,
         samplingFile: null,
         samplingFileForCal: null,
         samplingFileForCalForFastMode: null,
         sensorTypeId: '',
         sensorModel: '',
         serialNumber: '',
         timestamp: 0,
         timezone: 'America/Los_Angeles',
         totalCyclesCount: 0,
         weatherSensor: '',
         zoneId: '',
         zoneName: '',
      };
   }

   getAsObject(strUrl: string, nTake: number = 0): Observable<any> {
      if (nTake === 0) {
         return this.db
            .object(strUrl)
            .snapshotChanges()
            .pipe(map(action => this.convertToValue(action)));
      }

      return this.db
         .object(strUrl)
         .snapshotChanges()
         .pipe(
            take(nTake),
            map(action => this.convertToValue(action))
         );
   }

   observerAsObject(strUrl: string, nTake: number = 0): Observable<any> {
      const snapshot = this.db.object(strUrl).valueChanges();

      return nTake === 0 ? snapshot : snapshot.pipe(take(nTake));
   }

   getAsList(strUrl: string, nTake: number = 0): Observable<any> {
      if (nTake === 0) {
         return this.db
            .list(strUrl)
            .snapshotChanges()
            .pipe(
               map(arr => {
                  // @ts-ignore
                  return arr.map(action => this.convertToValue(action));
               })
            );
      }

      return this.db
         .list(strUrl)
         .snapshotChanges()
         .pipe(
            take(nTake),
            map(arr => {
               // @ts-ignore
               return arr.map(action => this.convertToValue(action));
            })
         );
   }

   getFilteredByTokenRun(strUrl: string, automToken: string): Observable<any> {
      const ref = automToken
         ? this.db.list(strUrl, ref => ref.orderByChild('automToken').equalTo(automToken))
         : this.db.list(strUrl, ref1 => ref1.limitToLast(1));

      return ref.snapshotChanges().pipe(
         take(1),
         map(arr => {
            // @ts-ignore
            return arr.map(action => this.convertToValue(action));
         })
      );
   }

   getListByOrder(strUrl: string, strOrder: string, strEqual: string, nTake: number = 0): Observable<any> {
      if (nTake === 0) {
         return this.db
            .list(strUrl, ref => ref.orderByChild(strOrder).equalTo(strEqual))
            .snapshotChanges()
            .pipe(
               map(arr => {
                  // @ts-ignore
                  return arr.map(action => this.convertToValue(action));
               })
            );
      }

      return this.db
         .list(strUrl, ref => ref.orderByChild(strOrder).equalTo(strEqual))
         .snapshotChanges()
         .pipe(
            take(nTake),
            map(arr => {
               // @ts-ignore
               return arr.map(action => this.convertToValue(action));
            })
         );
   }

   getListByOrderWithLimit(strUrl: string, strOrder: string, nLimit: number | undefined = 500): Observable<any> {
      if (nLimit === 1) {
         // If nLimit is undefined, don't apply any limit
         return this.db
            .list(strUrl)
            .snapshotChanges()
            .pipe(
               map(arr => {
                  // @ts-ignore
                  let data = arr.map(action => this.convertToValue(action));
                  return data;
               })
            );
      }

      return this.db
         .list(strUrl, ref => ref.orderByChild(strOrder).limitToLast(nLimit))
         .snapshotChanges()
         .pipe(
            map(arr => {
               // @ts-ignore
               let data = arr.map(action => this.convertToValue(action));
               return data.reverse(); // Reverse to get descending order
            })
         );
   }

   getListAsValuesByOrderWithLimit(strUrl: string, strOrder: string, nLimit: number = 500): Observable<any> {
      if (nLimit === -1) {
         // If nLimit is -1, don't apply any limit
         return this.db.list(strUrl, ref => ref.orderByChild(strOrder)).valueChanges();
      }
      // If nLimit is a positive number, apply the limit
      return this.db.list(strUrl, ref => ref.orderByChild(strOrder).limitToLast(nLimit)).valueChanges();
   }

   postAsObject(strUrl: string, value: any): Promise<any> {
      if (value !== undefined && value !== null) {
         return this.db.object(strUrl).set(value);
      }
      return Promise.reject('Value is undefined or null');
   }

   createAsList(strUrl: string, value: any): Promise<any> {
      return new Promise<any>((resolve, reject) => {
         this.db
            .list(strUrl)
            .push(value)
            .then(res => {
               resolve(res['key']);
            });
      });
   }

   updateAsObject(strUrl: string, value: any): Promise<any> {
      return this.db.object(strUrl).update(value);
   }

   deleteAsObject(strUrl: string): Promise<any> {
      return this.db.object(strUrl).remove();
   }

   convertToValue(res: any) {
      const returnVal = res.payload.val();
      if (!returnVal) {
         if (res['key']) {
            return {
               key: res['key'],
               $value: returnVal === 0 ? 0 : null,
            };
         } else {
            return null;
         }
      }

      if (typeof returnVal === 'object') {
         returnVal['key'] = res['key'];
         return returnVal;
      } else {
         return {
            key: res['key'],
            $value: returnVal,
         };
      }
   }

   async isSensorSchemaValid(strUrl: string): Promise<boolean> {
      try {
         const snapshot = await this.db.database.ref(strUrl).once('value');
         const sensorSchema = snapshot.val();

         return JSON.stringify(sensorSchema) === JSON.stringify(this.sensorSchemaTemplate);
      } catch (error) {
         console.error('Error in isSensorSchemaValid function:', error);
         return false;
      }
   }

   // ** This Proceess will be executed asynchronously. User will not feel or see any delay.
   async fixSensorSchema(strUrl: string): Promise<any> {
      try {
         const isValid = await this.isSensorSchemaValid(strUrl);
         // console.log(`Schema for sensor is valid: ${isValid}`);
         if (isValid) {
            console.log(`Schema for sensor is already valid, no action needed.`);
            return null;
         }

         console.log(`Schema for sensor is invalid, attempting to fix...`);
         // get , snapshot
         const snapshot = await this.db.database.ref(strUrl).once('value');
         const sensorSchema = snapshot.val();

         if (sensorSchema === null) {
            console.log('No data found at the given URL:', strUrl);
            return null;
         }

         // console.log('Original sensor schema:', sensorSchema);
         const finalSchema = this.sensorSchemaDeepCrawl(sensorSchema, this.sensorSchemaTemplate);
         // console.log('Updated sensor schema:', finalSchema);
         await this.db.object(strUrl).update(finalSchema);
         console.log(`Saved updated schema for sensor to database`);
         return finalSchema;
      } catch (error) {
         console.error(`Error checking/fixing schema for sensor:`, error);
         return null;
      }
   }
   sensorSchemaDeepCrawl(sensorSchema: any, sensorSchemaTemplate: any): any {
      if (sensorSchema === null || typeof sensorSchema !== 'object') {
         return { ...sensorSchemaTemplate };
      }

      const finalSchema: any = { ...sensorSchema };

      for (const key in sensorSchemaTemplate) {
         if (Object.prototype.hasOwnProperty.call(sensorSchemaTemplate, key)) {
            if (typeof sensorSchemaTemplate[key] === 'object' && !Array.isArray(sensorSchemaTemplate[key])) {
               if (!finalSchema.hasOwnProperty(key)) {
                  finalSchema[key] = this.sensorSchemaDeepCrawl(null, sensorSchemaTemplate[key]);
               } else {
                  finalSchema[key] = this.sensorSchemaDeepCrawl(finalSchema[key], sensorSchemaTemplate[key]);
               }
            } else if (!finalSchema.hasOwnProperty(key)) {
               finalSchema[key] = sensorSchemaTemplate[key];
            }
         }
      }

      return finalSchema;
   }
}
